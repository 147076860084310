<template>
    <div>
        <div v-if="showHeader" class="header_wrap w-100" ref="header">
            <div class="topBar-home">
                <div class="d-none d-lg-flex">
                    <div @click="$router.push('/web/dashboard')" class="">
                        <img src="./assets//images//header/logo2.png" width="100px">
                    </div>
                    <div class="mt-2 ml-3" @click="toHome()">
                        <img src="./assets/images/header/home-icon.png" width="35" height="auto">
                    </div>
                </div>
                <div class="input-box">
                    <b-input-group v-if="$route.path.includes('dashboard')">
                        <template #prepend>
                            <img src="./assets/images/icons/search.png" alt="search" class="mr-2" height="24px" width="24px">
                        </template>
                        <input type="text" v-model="searchCategory" @input="getProduct" class="form-control"
                            :placeholder="$t('search')">

                        <template #append v-if="productsLists.length > 0">
                            <img src="./assets/images/icons/close-black.png" alt="" height="24px" width="24px"
                                @click="clearSearch()">
                        </template>
                    </b-input-group>
                    <div class="search-outer-box">
                        <div class="search-borderbox">
                            <div v-for="item in productsLists" :key="item.id" class="search-box"
                                @click="navigateToProduct(item.id)">
                                <img :src="item.product_image_path[0].public_image_path" alt="">
                                <div>
                                    {{ $i18n.locale == 'zh' ? item.product_name_cn : item.product_name }}

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <div class="d-flex">
                    <div class="d-none d-lg-flex">
                        <div v-for="(value, name) in walletInfo" :key="name">
                            <div class="wallet-info-header">
                            <!-- <div class="">{{ $t(name) }}</div>  -->
                            <div v-if="name !== 'memberLevel'" class="value mt-3">RM {{ parseFloat(value).toLocaleString() }}</div>
                            <div v-else class="value">{{ value }}</div>
                        </div>
                        </div>
                    </div>
                    <div class="d-flex">
                        <img @click="$router.push('/web/product/cart')" src="./assets/images/product/cart.png"
                            height="35px" width="35px" alt="" class="m-2">
                        <img @click="$router.push('/web/notification')" src="./assets/images/product/ring.png" height="35px" width="35px" alt="" class="d-none d-lg-flex m-2">
                        <div v-b-toggle.sidebar-right>
                            <img src="./assets/images/product/user.png" height="35px" width="35px" class="d-none d-lg-flex m-2">
                        </div>
                    </div>
                    <div class="mt-2">
                        <b-dropdown id="dropdown-lang" left variant="link" toggle-class="my-dropdown" @show="toggleDropdown" @hide="toggleDropdown">
                            <template #button-content>
                                <span class="text-white text-16 font-weight-bold text-uppercase">
                                    <span v-if="$i18n.locale == 'zh'">{{ $i18n.locale }}</span>
                                    <span v-else>{{ 'en' }}</span>
                                </span>
                                <!-- <i :class="dropdownIcon" aria-hidden="true" class="text-white font-weight-bold ml-2 text-16"></i> -->
                                <!-- <span class="text-primary text-16 font-weight-bold text-uppercase">{{ $i18n.locale }}</span> -->
                            </template>
                            <b-dropdown-item href="#" @click="$root.changeLan('en')">English</b-dropdown-item>
                            <b-dropdown-item href="#" @click="$root.changeLan('zh')">中文</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </div>
        </div> 
        <b-sidebar id="sidebar-right" title="Sidebar" no-header right shadow backdrop>
            <div class="">
                <div class="topBar-sidebar py-4">
                    <div class="d-flex ml-4 mb-3">
                        <img src="./assets/images/profile/user.png" width="40px" height="40px" class="mt-2">
                        <div class="profile-banner mt-3 text-12">
                            <span class="text-14 font-weight-bold">{{ $root.userInfo.username }}</span>
                            <!-- <div v-if="$root.userInfo.premium_member == 1" class="profile-rankbox">Premium User</div>
                            <div v-else  class="profile-rankbox">Normal User</div> -->
                        </div>
                        
                    </div>
                </div>
                <div class="myorder-bg">
                    <div class="text-secondary text-left font-weight-bold text-16 ml-4">{{ $t('my_orders')}}</div>
                    <div class="myorder-box ml-4">   
                        <div v-for="item in myOrder" :key="item.name" class="myorder-item mt-2" @click="$router.push(item.path)">
                            <img :src="require(`./assets/images/profile/${item.name}.png`)" alt="my-order" class="myorder-icon">
                            <div class="myorder-text font-weight-bold">{{ $t(item.name) }}</div>
                        </div>
                    </div>
                </div>
                <div class="text-left" >
                
                    <div class="text-secondary font-weight-bold text-18 mb-3">{{ $t('general')}}</div>
                    <!-- <div v-for="item in bonus_record" :key="item" class="card-me mb-2"
                        @click="$router.push('/web/' + item)"> -->
                    <div v-for="item in bonus_record" :key="item" class="card-me mb-2"
                        >
                        <div class="d-flex justify-content-between">
                            <span>
                                <img :src="require('./assets/images/profile/' + item + '.png')" alt="" height="auto"
                                width="auto">
                                <span class=" text-14 font-weight-600 ml-2">{{ $t(item) }}</span>
                            </span>
                            <span><i class="fa fa-chevron-right mt-2"></i></span>
                        </div>
                    </div>
                    <div class="card-me text-center text-danger mt-3" @click="logout()">{{ $t('logout') }}</div>

                </div>
            </div>
        </b-sidebar>
    </div>   
</template>
<script>
import { getProduct } from '@/system/request/api/api';
export default {
    computed: {
        walletInfo() {
            const userInfo = this.$root.userInfo || {};
            return {
                // memberLevel: this.$root.userInfo.premium_member == 1 ? 'PREMIUM' : 'NORMAL',
                point3: parseFloat(userInfo.point3) || 0,
                // point1: parseFloat(userInfo.point1) || 0,
            };
        },
        showHeader() {
            const isMobile = window.innerWidth <= 768; // Define mobile screen width
            const isDashboard = this.$route.path === '/web/dashboard'; // Check if the route is dashboard

            return !isMobile || isDashboard; // Show header on desktop or on dashboard in mobile
        }
        // dropdownIcon() {
        //     return this.isDropdownOpen ? 'fa-solid fa-chevron-up' : 'fa-regular fa-chevron-down';
        // }
    },
    data() {
        return {
            searchCategory: '',
            productsLists: [],
            show: false,
            isDropdownOpen: false,
            companies: [
            ],
            myOrder: [
                // {name:'payment'},
                // {name:'process'},
                // {name:'confirmation'},
                {name:'orderHistory', path: '/web/product/orderHistory' },
                {name:'hotel_booking', path: '/web/hotel/orderHotelHistory'},
            ],
            bonus_record: [
                // "edit_profile",
                // "withdraw_details",
                // "language",
                // "announcement",
                // "security_center",
                "contact_us",   

            ]
        };
    },
    methods: {
        clearSearch() {
            this.searchCategory = '';
            this.productsLists = [];
        },
        navigateToProduct(id) {
            this.$router.push('/web/product/details?id=' + id);
            this.clearSearch(); 

            // Force reactivity update
            this.$nextTick(() => {
                this.productsLists = [];
        });
        },
        getProduct() {
            this.$root.isLoading = true;
            var result = getProduct(
                '',
                this.searchCategory,
                1
            );
            var self = this;
            result
                .then(function (value) {
                    self.$root.isLoading = false;
                    self.lastPage = value.data.data.mall_product.last_page;
                    self.productsLists = value.data.data.mall_product.data.map(product => {
                        let parsedPrice = JSON.parse(product.price);
                        return {
                            ...product,
                            price: parsedPrice,
                            normalCredit: parsedPrice.normal_credit,
                            premiumCredit: parsedPrice.premium_credit,
                            normalCash: parsedPrice.normal_cash,
                            premiumCash: parsedPrice.premium_cash
                        };
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        toHome() {
            var token = localStorage.getItem('currencyToken');
            var lang = localStorage.getItem('lan');
            var baseUrl = window.location.hostname === 'mall.jaboclub.com' ? 'https://jaboclub.com/web/' : 'https://uat.jaboclub.com/web/' ;
            
            if (token) {
                var url = `${baseUrl}?key=${encodeURIComponent(token)}&lan=${encodeURIComponent(lang)}`;
                window.location.href = url;
            } else {
                console.error('Token not found in localStorage');
            }
        },
        toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
        },
        logout() {
            localStorage.removeItem('currencyToken');
            localStorage.removeItem('info');
            var baseUrl = window.location.hostname === 'mall.jaboclub.com' ? 'https://jaboclub.com/web/' : 'https://uat.jaboclub.com/web/sessions/login' ;
            window.location.href = baseUrl;
        },
    },
}
</script>

<style>
.login{
    background-image: url('./assets/images/bg/bg3.png') !important;
}
</style>
